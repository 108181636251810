


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.demographicsBox {
  border: 1px solid gray;
  margin: 5px;
  padding: 1px;
  width: 200px;
  font-size: 12px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* Add this to your DemographicsBox.css file or wherever your styles are defined */
.group {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if there's not enough space */
  gap: 10px; /* Adjust the gap between boxes as needed */
}

.demographicsBox {
  background-color: #f9f9f9; /* Light grey background */
  border: 1px solid #e5e5e5; /* Slight border for definition */
  border-radius: 8px; /* Rounded corners */
  padding: 16px; /* Spacing inside each box */
  margin-bottom: 8px; /* Space between each box */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Soft shadow for depth */
  transition: transform 0.2s ease, box-shadow 0.2s ease; /* Smooth transition for hover effects */
}

.demographicsBox:hover {
  transform: translateY(-2px); /* Slight lift effect on hover */
  box-shadow: 0 4px 8px rgba(0,0,0,0.2); /* Enhanced shadow on hover for depth */
}

.demographicDetail {
  margin-bottom: 4px; /* Spacing between details */
  font-size: 16px; /* Readable text size */
  color: black
}

/* If you have specific labels or values, you can style them further */
.demographicLabel {
  font-weight: bold; /* Make labels bold */
}

.demographicValue {
  color: #333; /* Slightly darker text for contrast */
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 0.5s ease-out;
}
