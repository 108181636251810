/* DemographicsBox.css */
.demographicsBox {
    border: 1px solid gray;
    margin: 5px;
    padding: 5px;
    width: 200px;
    font-size: 12px;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
